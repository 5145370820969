import Chart from './3d/chart';
import { useEffect, useRef, useState } from 'react';
import { message, Spin } from 'antd';
import mockData from './mockData.json';

import { useTranslation } from 'react-i18next';
import useViewport from 'src/hooks/generic/useViewport';
import { WarningOutlined } from '@ant-design/icons';
import { GradientKey } from 'src/models/GradientKey';
import { HumidityData } from 'src/views/objects/Balancing/BalancingView';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';

interface House3DProps {
    objectId: number;
    stateId: number;
    sensors: {
        sensorName?: string;
        id: number;
    }[];
    referenceTemperature: number;
    onNavigateToTable: () => void;
    selectedSensors: number[];
    tolerance?: number;
    humidityLevel?: number;
    onSensorSelected?: (id: number) => void;
    onSensorDeselected?: (id: number) => void;
    gradientKey?: GradientKey;
    humidityData: HumidityData[];
    viewMode: string[];
    data: V3D_Object;
    error: any;
}

function MockChart() {
    return (
        <Chart
            onPointerOver={(id, over) => {}}
            selectedSensors={[]}
            onSensorSelected={(id, selected) => {}}
            referenceTemperature={0}
            objects={[mockData]}
            tolerance={1.0}
            continuousSpin={true}
        />
    );
}

function House3D(props: House3DProps) {
    const {
        onNavigateToTable,
        selectedSensors,
        onSensorSelected,
        onSensorDeselected,
        sensors,
        tolerance,
        referenceTemperature,
        gradientKey,
        humidityData,
        humidityLevel,
        viewMode,
        data,
        error,
    } = props;

    const [selectedSensor, setSelectedSensor] = useState<string>();
    const { t } = useTranslation();

    const { mode } = useViewport();
    const container = useRef(null);

    useEffect(() => {
        if (error)
            message.error(
                t(
                    `3D model is incomplete. Please contact us for more information.`,
                ),
                7,
            );
    }, [error, t]);

    return (
        <div
            className="relative w-full h-full"
            ref={container}
            style={{ minHeight: mode === 'desktop' ? '30vh' : '40vh' }}
        >
            <b className="absolute right-0 top-0">{selectedSensor}</b>
            {data &&
                data.blocks.length !== 0 &&
                !Number.isNaN(referenceTemperature) && (
                    <>
                        <Chart
                            onPointerOver={(id, over) => {
                                if (over) {
                                    const sensor = sensors.find(
                                        (sensor) => sensor.id === id,
                                    );

                                    setSelectedSensor(sensor?.sensorName);
                                } else {
                                    setSelectedSensor(undefined);
                                }
                            }}
                            selectedSensors={selectedSensors}
                            onSensorSelected={(id, selected) => {
                                if (selected) {
                                    onSensorSelected?.(id);
                                } else {
                                    onSensorDeselected?.(id);
                                }
                            }}
                            referenceTemperature={referenceTemperature!}
                            objects={[data]}
                            tolerance={tolerance}
                            continuousSpin={false}
                            gradientKey={gradientKey}
                            humidityData={humidityData}
                            humidityLevel={humidityLevel}
                            viewMode={viewMode}
                        />
                        {/* <div className="absolute transform -translate-x-1/2 left-1/2 bottom-0">
                        <label htmlFor="reference-temperature">
                            {Number.isNaN(referenceTemperature)
                                ? ''
                                : referenceTemperature}
                            °C
                        </label>
                        <Slider
                            className="w-56"
                            step={1}
                            min={Math.min(
                                ...data.averageTemperatures.map(
                                    (dp: any) => dp.fromTemperature,
                                ),
                            )}
                            max={Math.max(
                                ...data.averageTemperatures.map(
                                    (dp: any) => dp.fromTemperature,
                                ),
                            )}
                            value={referenceTemperature}
                            onChange={(e: number) => setReferenceTemperature(e)}
                            disabled={Number.isNaN(referenceTemperature)}
                        />
                    </div> */}
                    </>
                )}
            {data && data.blocks.length === 0 && (
                <>
                    {/* Overlay */}
                    <div className="w-full h-full flex items-center justify-center top-0  absolute z-30">
                        <div className="bg-black opacity-50 absolute w-full h-full z-0" />
                        <b className="text-white z-10">
                            {t('3D model missing. Contact us to get one.')}
                            <br />
                            <b
                                onClick={onNavigateToTable}
                                className="underline cursor-pointer"
                            >
                                {t('Press here for table view')}
                            </b>
                        </b>
                    </div>

                    <MockChart />
                </>
            )}
            {!data && !error && (
                <div className="absolute text-center flex align-middle flex-col left-1/2 top-1/2 transform -translate-x-1/2">
                    <h2>{t('Loading 3D model')}</h2>
                    <Spin />
                </div>
            )}
            {error && (
                <div className="absolute text-center flex align-middle flex-col left-1/2 top-1/2 transform -translate-x-1/2">
                    <h2>
                        {<WarningOutlined />} {t('Could not load 3D model')}
                    </h2>
                </div>
            )}
        </div>
    );
}

export default House3D;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'src/api/API';
import {
    selectAverageTemperaturesControlState,
    setAverageTemperaturesControlState,
} from 'src/store/reducers/avgTemperaturesControlStateSlice';

export const useAverageTemperaturesWithControlState = (
    id: number,
    controlState: number,
) => {
    const dispatch = useDispatch();

    // Local loading and error states
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Access cache from Redux store
    const cachedData = useSelector(
        selectAverageTemperaturesControlState(`${controlState}-${id}`),
    );

    const prevData = useSelector(
        selectAverageTemperaturesControlState(`${controlState - 1}-${id}`),
    );

    useEffect(() => {
        // If cached data is available, do not fetch again
        if (cachedData !== undefined) {
            return;
        }

        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const [currentData, prevData] = await Promise.all([
                    new API().fetchAverageTempertureWithControlState(
                        id,
                        controlState,
                    ),
                    controlState > 0
                        ? new API().fetchAverageTempertureWithControlState(
                              id,
                              controlState - 1,
                          )
                        : Promise.resolve(undefined),
                ]);

                // Store raw data and interval in Redux
                dispatch(
                    setAverageTemperaturesControlState({
                        key: `${controlState}-${id}`,
                        data: currentData,
                    }),
                );

                if (prevData) {
                    dispatch(
                        setAverageTemperaturesControlState({
                            key: `${controlState - 1}-${id}`,
                            data: prevData,
                        }),
                    );
                }
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, controlState, cachedData, id]);

    return { data: cachedData, previous: prevData, isLoading, error };
};

// src/hooks/use3DDataRealtimeTemperatures.ts
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRealtimeRawTemperatures,
    setRealtimeRawTemperatures,
} from 'src/store/reducers/realtimeRawTemperaturesSlice';
import { setRealtimeTemperatures } from 'src/store/reducers/realtimeTemperturesSlice';
import { setIntervalRealtimeGraph } from 'src/store/reducers/realTimeVisualization';
import { API } from 'src/api/API';

const use3DDataRealtimeTemperatures = (
    objectId: number,
    start: string,
    end: string,
) => {
    const dispatch = useDispatch();

    // Local loading and error states
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Access cache from Redux store
    const cachedData = useSelector(
        selectRealtimeRawTemperatures(objectId, start, end),
    );

    useEffect(() => {
        // If cached data is available, do not fetch again
        if (cachedData !== undefined) {
            return;
        }

        // If no cached data, start loading and fetch new data
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await new API().fetchTemperaturesRealTimeVisualization(
                    objectId,
                    start,
                    end,
                );

                // Store raw data and interval in Redux
                dispatch(
                    setRealtimeRawTemperatures({
                        key: `${objectId}-${start}-${end}`,
                        data: response['raw_data'] || [],
                    }),
                );

                dispatch(setRealtimeTemperatures(response?.data));
                dispatch(setIntervalRealtimeGraph(response['interval'] || 1));
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, objectId, start, end, cachedData]);

    return { data: cachedData, isLoading, error };
};

export default use3DDataRealtimeTemperatures;

import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import { scaleTime, scaleLinear } from 'd3-scale';
import {
    ComposedChart,
    Tooltip,
    Legend,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

import { useDispatch, useSelector } from 'react-redux';
import { selectRealtimeVisualization } from 'src/store/reducers/realTimeVisualization';
import { selectRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import { setRealTimeDate } from 'src/store/reducers/realTimeVisualization';
import useViewport from 'src/hooks/generic/useViewport';
import {
    gradients,
    generateGradientKey,
    getGradientColorForRealtimeVisualization,
} from 'src/utils/gradients';
import { Sensor } from 'src/models/Sensor';
import { RootState } from 'src/store/rootReducer';

import { minMaxArrValue } from 'src/utils/MinMax';

interface GraphSliderRealtimeProps {
    data?: any;
    avgObjData: any;
    avgData: any;
    start: string;
    high: number;
    low: number;
    newTimestamps: number[];
}

interface RealTimeDataPoint {
    date: string;
    timestamp: number;
    value: number;
}

interface RealTimeResponse {
    data: RealTimeDataPoint[];
    id: number;
    name: string;
}

interface CustomizedDotProps {
    cx?: number;
    cy?: number;
    stroke?: string;
    payload?: any;
    value?: number;
    index?: number;
    closestDataPoint?: any;
    id: number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: number;
}

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

function formatTimestamp(label: number): string {
    const date = new Date(label * 1000);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const stringHours = hours > 9 ? hours.toString() : '0' + hours.toString();

    return `${day} ${month}, ${stringHours}:${minutes.slice(-2)}`;
}

const GraphSliderRealtime: React.FC<GraphSliderRealtimeProps> = memo(
    ({
        data,
        avgObjData,
        avgData: avgDataFloors,
        start,
        high,
        low,
        newTimestamps,
    }) => {
        const [realtimeData, setRealtimeData] = useState<any[]>([]);
        const [avgDataRealtime, setAvgDataRealtime] = useState<any>();
        const [realTimeSensors, setRealTimeSensors] = useState<
            RealTimeResponse[]
        >([]);
        const [isDragging, setIsDragging] = useState(false);
        const [closestDataPoint, setClosestDataPoint] = useState<any>(null);
        const { mode } = useViewport();

        const chartRef = useRef<HTMLDivElement>(null);
        const dispatch = useDispatch();

        const { realTimeDate } = useSelector(selectRealtimeVisualization);
        const selectedRealtimeSensors = useSelector(selectRealtimeSensors);

        const { selectedFloorSensors } = useSelector(
            (state: RootState) => state.selectedObject,
        );

        const CustomTooltip: React.FC<CustomTooltipProps> = ({
            active,
            payload,
            label,
        }) => {
            if (active && payload && closestDataPoint) {
                const { unit } = payload[0];
                const { id, name, timestamp, value }: any = closestDataPoint;

                const sensor = realTimeSensors.find(
                    (sensor) => sensor.id === id,
                );

                return (
                    <div
                        className="custom-tooltip"
                        style={{
                            border: '1px solid rgb(204, 204, 204)',
                            background: '#fff',
                            padding: '10px',
                        }}
                    >
                        {label ? (
                            <div>{`${formatTimestamp(timestamp)}`}</div>
                        ) : null}
                        <div
                            style={{
                                color: getGradientColorForRealtimeVisualization(
                                    sensor?.data,
                                    gradientKey,
                                    null,
                                ),
                            }}
                        >
                            {`${name} : ${value?.toFixed(1)}${unit}`}
                        </div>
                    </div>
                );
            } else if (
                mode !== 'mobile' &&
                active &&
                payload &&
                payload.length
            ) {
                const { name, value, unit, color } = payload[0];

                return (
                    <div
                        className="custom-tooltip"
                        style={{
                            border: '1px solid rgb(204, 204, 204)',
                            background: '#fff',
                            padding: '10px',
                        }}
                    >
                        {label ? (
                            <div>{`${formatTimestamp(label)}`}</div>
                        ) : null}
                        <div style={{ color: color }}>
                            {`${name} : ${value?.toFixed(1)}${unit}`}
                        </div>
                    </div>
                );
            }

            return null;
        };

        const CustomizedDot: React.FC<CustomizedDotProps> = (props) => {
            const { cx, cy, stroke, payload, closestDataPoint, id } = props;

            // Check if this dot is the closest data point
            if (
                cx &&
                cy &&
                closestDataPoint &&
                payload.timestamp === closestDataPoint.timestamp &&
                closestDataPoint.id === id
            ) {
                return (
                    <circle
                        cx={cx}
                        cy={cy}
                        r={4}
                        stroke={stroke}
                        fill={stroke}
                    />
                );
            }
            return null;
        };

        useEffect(() => {
            let transformedData: any[] = [];

            if (data) {
                data.forEach((datapoint: any) => {
                    if (
                        datapoint.data &&
                        datapoint.data.length &&
                        selectedRealtimeSensors.includes(datapoint.id)
                    ) {
                        datapoint.data.forEach((temperature: any) => {
                            if (temperature.timestamp) {
                                const date = new Date(temperature.timestamp);
                                if (date >= new Date(start)) {
                                    transformedData.push({
                                        id: datapoint.id,
                                        name: datapoint.name,
                                        date,
                                        timestamp: date.getTime() / 1000,
                                        value: temperature.value,
                                    });
                                }
                            }
                        });
                    }
                });

                transformedData = transformedData.filter(
                    (item: any) =>
                        item.timestamp <=
                        newTimestamps[newTimestamps.length - 1],
                );

                transformedData.sort((a, b) => a.timestamp - b.timestamp);

                setRealtimeData(transformedData);

                if (transformedData.length > 0) {
                    const middleIndex = Math.floor(transformedData.length / 2);
                    const value = transformedData[middleIndex].timestamp;
                    dispatch(setRealTimeDate(value));
                }
            }
            //eslint-disable-next-line
    }, [data, selectedRealtimeSensors]);

        useEffect(() => {
            if (selectedRealtimeSensors.length) {
                const sensorData: RealTimeResponse[] = [];
                data.forEach((datapoint: any) => {
                    if (datapoint.data && datapoint.data.length) {
                        if (selectedRealtimeSensors.includes(datapoint.id)) {
                            const temperatures: RealTimeDataPoint[] = [];
                            datapoint.data.forEach((temperature: any) => {
                                const date = new Date(temperature.timestamp);
                                if (
                                    temperature.timestamp &&
                                    date >= new Date(start)
                                ) {
                                    temperatures.push({
                                        date: temperature.date,
                                        timestamp:
                                            new Date(
                                                temperature.timestamp,
                                            ).getTime() / 1000,
                                        value: temperature.value,
                                    });
                                }
                            });
                            sensorData.push({
                                data: temperatures,
                                id: datapoint.id,
                                name: datapoint.name,
                            });
                        }
                    }
                });
                setRealTimeSensors(sensorData);
            } else {
                setRealTimeSensors([]);
            }
            //eslint-disable-next-line
    }, [selectedRealtimeSensors]);

        useEffect(() => {
            if (
                avgObjData &&
                avgObjData.length &&
                avgObjData[0].data &&
                avgObjData[0].data.length
            ) {
                const interval6Hours = 6 * 60 * 60;
                const filteredData = avgObjData[0].data.filter(
                    (dataPoint: any) =>
                        dataPoint.timestamp % interval6Hours === 0 &&
                        dataPoint.timestamp <=
                            newTimestamps[newTimestamps.length - 1],
                );
                const transformedData = {
                    ...avgObjData[0],
                    data: filteredData,
                };
                setAvgDataRealtime([transformedData]);
            }
        }, [avgObjData, newTimestamps]);

        const avgData = useMemo(() => {
            return avgDataRealtime?.[0]?.data
                ? avgDataRealtime[0].data.map((item: any) => ({
                      ...item,
                      id: avgDataRealtime[0].id,
                      name: avgDataRealtime[0].name,
                  }))
                : [];
        }, [avgDataRealtime]);

        const aggregatedFloorData = useMemo(() => {
            return avgDataFloors?.length
                ? avgDataFloors.flatMap((floor: any) =>
                      floor.data.map((item: any) => ({
                          ...item,
                          id: floor.id,
                          name: floor.name,
                      })),
                  )
                : [];
        }, [avgDataFloors]);

        const dataToCompare = useMemo(() => {
            const combinedData = [
                ...realtimeData,
                ...avgData,
                ...aggregatedFloorData,
            ];
            return combinedData.sort((a, b) => a.timestamp - b.timestamp);
        }, [realtimeData, avgData, aggregatedFloorData]);

        function colorPicker(sensor: Sensor, i: number = 0) {
            const floorColors = [
                '#FF0000',
                '#00FF00',
                '#0000FF',
                '#FFFF00 ',
                '#FF00FF',
                '#00FFFF ',
                '#800080 ',
                '#808080',
            ];

            const index =
                selectedFloorSensors.findIndex(
                    (floorSensor) => floorSensor.id === sensor.id,
                ) || 0;

            if (index === -1) return '#FF0000';
            return floorColors[index % floorColors.length];
        }

        const handleClick = (event: any) => {
            const x =
                event.activePayload && event.activePayload[0]
                    ? event.activePayload[0].payload.timestamp
                    : null;
            if (x !== null && x !== undefined) {
                dispatch(setRealTimeDate(x));
            }
        };

        const handleMouseDown = (event: any) => {
            const x =
                event.activePayload && event.activePayload[0]
                    ? event.activePayload[0].payload.timestamp
                    : null;
            if (x !== null && x !== undefined) {
                dispatch(setRealTimeDate(x));
                setIsDragging(true);
            }
        };

        const handleMouseMove = (event: any) => {
            if (isDragging && mode !== 'mobile') {
                const x =
                    event.activePayload && event.activePayload[0]
                        ? event.activePayload[0].payload.timestamp
                        : null;
                if (x !== null && x !== undefined) {
                    dispatch(setRealTimeDate(x));
                }
                setClosestDataPoint(null);
                return;
            }

            if (event.isTooltipActive || event.isMobile) {
                // const { activeTooltipIndex, activeLabel } = event;
                // const closestData = realtimeData[activeTooltipIndex];

                const chartElement = chartRef.current;

                const chartWidth = chartElement
                    ? chartElement.getBoundingClientRect().width
                    : 0;
                let chartHeight = 180; //default

                const cartesianGrid = chartElement?.querySelector(
                    '.recharts-cartesian-grid',
                );
                if (cartesianGrid) {
                    chartHeight = cartesianGrid.getBoundingClientRect().height;
                }

                const { chartX, chartY } = event;

                let closestDataPoint: any = null;
                let minDistance = Infinity;

                // const xScale = scaleTime()
                //         .domain([new Date(Math.min(...realtimeData.map(d => d.timestamp * 1000))), new Date(Math.max(...realtimeData.map(d => d.timestamp * 1000)))]) // Data domain
                //         .range([0, chartWidth])

                const domainToDetermine = minMaxArrValue(1, [
                    avgDataFloors || [],
                    avgObjData || [],
                    realTimeSensors || [],
                ]);

                const xPaddingLeft = 15;
                const xPaddingRight = 0;
                const yPaddingBottom = 20;

                const newYscaleToDetermine = scaleLinear()
                    .domain(domainToDetermine)
                    .range([250 - yPaddingBottom, 250 - chartHeight]);

                dataToCompare.sort((a, b) => a.timestamp - b.timestamp);

                const firstDate = new Date(newTimestamps[0] * 1000);

                const lastDate = new Date(
                    newTimestamps[newTimestamps.length - 1] * 1000,
                );

                const xScaleToDetermine = scaleTime()
                    .domain([new Date(firstDate), new Date(lastDate)])
                    .range([xPaddingLeft, chartWidth - xPaddingRight]);

                dataToCompare.forEach((dataPoint) => {
                    // Calculate the X and Y positions of the data point
                    const dataPointX = xScaleToDetermine(
                        new Date(dataPoint.timestamp * 1000),
                    );
                    const dataPointY = newYscaleToDetermine(dataPoint.value);

                    // Calculate the distance from the mouse position to the data point
                    // Euclidean distance
                    const distance = Math.sqrt(
                        Math.pow(chartX - dataPointX, 2) +
                            Math.pow(chartY - dataPointY, 2),
                    );

                    if (distance < minDistance) {
                        minDistance = distance;
                        closestDataPoint = dataPoint;
                    }
                });

                if (closestDataPoint) {
                    const index = dataToCompare.findIndex(
                        (point: any) => point.id === closestDataPoint.id,
                    );
                    closestDataPoint.index = index;
                }

                setClosestDataPoint(closestDataPoint);
            }
        };

        const handleMouseUp = (e: any) => {
            setIsDragging(false);
            //remove closestdatapoint
            if (mode !== 'mobile') {
                setClosestDataPoint(null);
            }
        };

        const gradientKey = generateGradientKey(
            { high, low },
            gradients.thermometer.mix,
        );
        const memoizedData = useMemo(() => realtimeData, [realtimeData]);

        const labelFormatter = (label: number) => formatTimestamp(label);
        const valueFormatter = (value: any) => `${value.toFixed(1)}`;
        const CustomTick = () => {
            return <></>;
        };

        const handleTouchMove = (e: any) => {
            const touch = e.touches[0];

            const chartRect = chartRef.current?.getBoundingClientRect();

            if (!chartRect || !touch) return;

            if ('clientX' in touch && 'clientY' in touch) {
                // Calculate chartX and chartY relative to the chart's top-left corner
                const chartX = touch.clientX - chartRect.left;
                const chartY = touch.clientY - chartRect.top;

                const customMouseEvent = {
                    chartX,
                    chartY,
                    isMobile: true,
                };

                handleMouseMove(customMouseEvent);
            }
        };

        const handleMouseLeave = (e: any) => {
            setIsDragging(false);
            //remove closestdatapoint
            setClosestDataPoint(null);
        };

        return (
            <div
                ref={chartRef}
                onTouchStart={handleTouchMove}
                onTouchEnd={handleTouchMove}
            >
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                        key={'composedChart'}
                        className="m-auto"
                        data={memoizedData}
                        margin={{ top: 20, left: 0, bottom: 20, right: 0 }}
                        onClick={handleClick}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseLeave}
                    >
                        <CartesianGrid strokeDasharray="5 5" />
                        <XAxis
                            type="number"
                            dataKey="timestamp"
                            domain={[
                                newTimestamps[0],
                                newTimestamps[newTimestamps.length - 1],
                            ]}
                            scale="time"
                            tick={<CustomTick />}
                            //tickLine={{ display: 'none' }} // Hide tick lines
                            tickFormatter={(v) => {
                                const date = new Date(v * 1000);
                                const day = date.getDate();
                                const month = monthNames[date.getMonth()];
                                const formattedHour =
                                    date.getHours() +
                                    ':' +
                                    ('0' + date.getMinutes()).substr(-2);

                                return day + month + ' ' + formattedHour;
                            }}
                            interval={'preserveStartEnd'}
                            ticks={newTimestamps}
                            angle={-45}
                            //interval={0}
                            allowDataOverflow={false} // to make reference line work
                            padding={{ left: 15 }}
                        />
                        <YAxis
                            //width={25}
                            yAxisId="left"
                            padding={{ bottom: 20 }}
                            unit="°C"
                            mirror={true}
                            orientation="left"
                            domain={minMaxArrValue(1, [
                                avgDataFloors || [],
                                avgObjData || [],
                                realTimeSensors || [],
                            ])}
                            allowDataOverflow={true}
                            //tickFormatter={(v) => v.toFixed(0)}
                            allowDuplicatedCategory={false}
                        />
                        <YAxis
                            //width={25}
                            yAxisId="right"
                            padding={{ bottom: 20 }}
                            unit="°C"
                            mirror={true}
                            orientation="right"
                            domain={minMaxArrValue(1, [
                                avgDataFloors || [],
                                avgObjData || [],
                                realTimeSensors || [],
                            ])}
                            allowDataOverflow={true}
                            //tickFormatter={(v) => v.toFixed(0)}
                            allowDuplicatedCategory={false}
                        />
                        <Tooltip
                            content={<CustomTooltip />}
                            formatter={valueFormatter}
                            labelFormatter={labelFormatter}
                        />
                        <Legend
                            wrapperStyle={{
                                paddingBottom: '5px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                maxHeight: '60px',
                                overflowY: 'auto',
                            }}
                            verticalAlign="top"
                            align="center"
                        />
                        {realTimeSensors.map((sensor, i) => {
                            return (
                                <Line
                                    isAnimationActive={false}
                                    yAxisId="left"
                                    connectNulls={true}
                                    stroke={getGradientColorForRealtimeVisualization(
                                        sensor.data,
                                        gradientKey,
                                        null,
                                    )}
                                    strokeWidth={3}
                                    name={sensor.name}
                                    unit="°C"
                                    dataKey="value"
                                    key={sensor.id}
                                    data={sensor.data.filter(
                                        (item) =>
                                            item.timestamp <=
                                            newTimestamps[
                                                newTimestamps.length - 1
                                            ],
                                    )}
                                    dot={
                                        <CustomizedDot
                                            stroke={getGradientColorForRealtimeVisualization(
                                                sensor.data,
                                                gradientKey,
                                                null,
                                            )}
                                            id={sensor.id}
                                            closestDataPoint={closestDataPoint}
                                        />
                                    }
                                    // //onMouseOut={handleMouseOut}
                                    activeDot={false}
                                />
                            );
                        })}
                        {avgDataRealtime &&
                            !!avgDataRealtime.length &&
                            avgDataRealtime?.map((v: any) => {
                                return (
                                    <Line
                                        yAxisId="left"
                                        isAnimationActive={false}
                                        strokeWidth={3}
                                        key={v.id}
                                        connectNulls={true}
                                        unit="°C"
                                        name={v.name || v.uniqueId}
                                        type="linear"
                                        dataKey="value"
                                        stroke={'#f5d742'}
                                        data={v.data}
                                        dot={
                                            <CustomizedDot
                                                stroke={'#f5d742'}
                                                closestDataPoint={
                                                    closestDataPoint
                                                }
                                                id={v.id}
                                            />
                                        }
                                        //onMouseOut={handleMouseOut}
                                        activeDot={false}
                                    />
                                );
                            })}
                        {selectedFloorSensors &&
                            selectedFloorSensors.length &&
                            avgDataFloors?.map((v: any, i: number) => {
                                return (
                                    <Line
                                        yAxisId="left"
                                        isAnimationActive={false}
                                        strokeWidth={3}
                                        key={v.id}
                                        connectNulls={true}
                                        unit="°C"
                                        name={v.name || v.uniqueId}
                                        type="linear"
                                        dataKey="value"
                                        stroke={colorPicker(v, i)}
                                        data={v.data}
                                        dot={
                                            <CustomizedDot
                                                stroke={colorPicker(v, i)}
                                                closestDataPoint={
                                                    closestDataPoint
                                                }
                                                id={v.id}
                                            />
                                        }
                                        activeDot={false}
                                    />
                                );
                            })}
                        {realTimeDate && (
                            <ReferenceLine
                                yAxisId="left"
                                x={realTimeDate}
                                stroke="#808080"
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    },
);

export default GraphSliderRealtime;

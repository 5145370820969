import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { Menu, Typography, Slider } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';
import React from 'react';
import './PreferencesMenu.scss';

interface PreferencesMenuProps {
    threshold: number;
    humidityLevel: number;
    setThreshold: (value: number) => void;
    setHumidityLevel: (value: number) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    data: V3D_Object;
    viewMode: string[];
    isRealtime: boolean;
    isHumidityDataAvailable: boolean;
}

const PreferencesMenu: FC<PreferencesMenuProps> = ({
    threshold,
    humidityLevel,
    isRealtime,
    setThreshold,
    setHumidityLevel,
    referenceTemperature,
    setReferenceTemperature,
    data,
    viewMode,
    isHumidityDataAvailable,
}) => {
    const { t } = useTranslation();

    const [isReferenceSet, setIsReferenceSet] = useState(false);

    const memoizedViewMode = useMemo(() => viewMode, [viewMode]);
    const memoizedData = useMemo(() => data, [data]);

    const handleThresholdChange = useCallback((v: number) => setThreshold(v), [
        setThreshold,
    ]);
    const handleHumidityLevelChange = useCallback(
        (v: number) => setHumidityLevel(v),
        [setHumidityLevel],
    );

    useEffect(() => {
        if (memoizedData !== undefined && !isReferenceSet) {
            const min = Math.min(
                ...memoizedData.averageTemperatures.map(
                    (dp) => dp.fromTemperature,
                ),
            );
            const max = Math.max(
                ...memoizedData.averageTemperatures.map(
                    (dp) => dp.fromTemperature,
                ),
            );

            if (Number.isNaN(referenceTemperature)) {
                if (memoizedData.referenceTemperatures?.pseudoDOT) {
                    setReferenceTemperature(
                        Math.round(
                            memoizedData.referenceTemperatures.pseudoDOT,
                        ),
                    );
                    setIsReferenceSet(true);
                } else if (memoizedData.referenceTemperatures?.dot) {
                    setReferenceTemperature(
                        Math.round(memoizedData.referenceTemperatures.dot),
                    );
                    setIsReferenceSet(true);
                }
            } else if (!Number.isFinite(referenceTemperature)) {
                setReferenceTemperature(NaN);
                setIsReferenceSet(true);
            } else {
                if (min > referenceTemperature) {
                    setReferenceTemperature(min);
                    setIsReferenceSet(true);
                }
                if (max < referenceTemperature) {
                    setReferenceTemperature(max);
                    setIsReferenceSet(true);
                }
            }
        }
    }, [
        memoizedData,
        referenceTemperature,
        isReferenceSet,
        setReferenceTemperature,
    ]);

    const temperatureSelected = !!memoizedViewMode.some(
        (option) => option.toLowerCase() === 'temperature',
    );

    const conclusionSelected = !!memoizedViewMode.some(
        (option) => option.toLowerCase() === 'conclusion',
    );

    return (
        <Menu
            id="preferences"
            style={{
                width: '200px',
                lineHeight: '23px',
                border: 'none !important',
            }}
            mode="horizontal"
            disabled={isRealtime}
        >
            <Menu.SubMenu
                title={
                    <span>
                        <EllipsisOutlined
                            style={{ transform: 'rotate(90deg)' }}
                        />
                        {t('Controls')}
                    </span>
                }
            >
                <div className="preferences-menu">
                    {(temperatureSelected || conclusionSelected) && (
                        <div className="preference-item">
                            <Typography.Text className="preference-label">
                                {t('Temperature level')}
                            </Typography.Text>
                            <Slider
                                className="preference-slider"
                                value={threshold}
                                step={0.5}
                                max={3}
                                min={0}
                                onChange={handleThresholdChange}
                            />
                        </div>
                    )}
                    {(isHumidityDataAvailable || conclusionSelected) && (
                        <div className="preference-item">
                            <Typography.Text className="preference-label">
                                {t('Humidity level')}
                            </Typography.Text>
                            <Slider
                                className="preference-slider"
                                value={humidityLevel}
                                step={0.05}
                                max={0.3}
                                min={0}
                                onChange={handleHumidityLevelChange}
                            />
                        </div>
                    )}
                </div>
            </Menu.SubMenu>
        </Menu>
    );
};

const areEqual = (
    prevProps: PreferencesMenuProps,
    nextProps: PreferencesMenuProps,
) => {
    return (
        prevProps.threshold === nextProps.threshold &&
        prevProps.humidityLevel === nextProps.humidityLevel &&
        prevProps.isRealtime === nextProps.isRealtime &&
        prevProps.referenceTemperature === nextProps.referenceTemperature &&
        prevProps.isHumidityDataAvailable ===
            nextProps.isHumidityDataAvailable &&
        JSON.stringify(prevProps.viewMode) ===
            JSON.stringify(nextProps.viewMode) &&
        JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
    );
};

export default React.memo(PreferencesMenu, areEqual);

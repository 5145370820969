import { API } from 'src/api/API';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAvgObjTemperatures,
    setAvgObjTemperatures,
} from 'src/store/reducers/avgObjTemperaturesSlice';

const useAvgTempObjRealTime = (
    objectId: number,
    start: string,
    end: string,
) => {
    const dispatch = useDispatch();

    // Local loading and error states
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Access cache from Redux store
    const cachedData = useSelector(
        selectAvgObjTemperatures(objectId, start, end),
    );

    useEffect(() => {
        // If cached data is available, do not fetch again
        if (cachedData !== undefined) {
            return;
        }

        // If no cached data, start loading and fetch new data
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const data = await new API().fetchRealTimeWithAvgObj(
                    objectId,
                    start,
                    end,
                    101,
                );
                const dataToPush = [];

                if (data.avgTemp && data.avgTemp.length) {
                    dataToPush?.push({
                        id: Math.floor(Math.random() * 9000000) + 1000000,
                        name: 'Medeltemperatur',
                        sensorFunctionTypeId: -1,
                        data: data.avgTemp,
                    });
                }

                // Store raw data and interval in Redux
                dispatch(
                    setAvgObjTemperatures({
                        key: `${objectId}-${start}-${end}`,
                        data: dataToPush || [],
                    }),
                );
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, objectId, start, end, cachedData]);

    return { data: cachedData, isLoading, error };
};

export default useAvgTempObjRealTime;

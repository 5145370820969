import { Typography, Divider, Row, Col, Tree, Skeleton } from 'antd';
import { useState, useEffect } from 'react';
import { HomeOutlined, ControlOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './Sidebar.module.scss';
import Nav from 'src/utils/nav/Nav';
import { useMe } from 'src/hooks/users/useMe';
import SideTab from './Sidetab';
import { CorporationLogo } from 'src/components/CorporationLogo';
import { useTree } from 'src/hooks/tree/useCorporationTree';
import { NumberParam, withDefault, useQueryParams } from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSensors } from 'src/store/reducers/selectedSensorsSlice';
import { setSelectedRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import {
    setNodeType,
    addFloorOrBlockSensor,
    removeExistingFloorSensor,
} from 'src/store/reducers/selectedObjectSlice';
import { setTreeData, selectTreeData } from 'src/store/reducers/treeSlice';
import {
    setSelectedKeys,
    selectSelectedKeys,
    addToExistingKeys,
    clearSelectedKeys,
    excludeExistingKeys,
} from 'src/store/reducers/selectedKeysSlice';

import { setRefreshFlag } from 'src/store/reducers/selectedObjectSlice';

import type { TreeProps } from 'antd/es/tree';

import { CaretDownFilled } from '@ant-design/icons';

interface IProps {
    active: string;
}

interface TreeDataNode {
    id: number;
    key: string;
    title: string;
    type: string;
    disabled: boolean;
    children?: TreeDataNode[];
}

function Sidebar(props: IProps) {
    const { data: me } = useMe();
    const { data: myData } = useTree();

    const dispatch = useDispatch();

    const [params] = useQueryParams({
        index: withDefault(NumberParam, 0),
    });

    const [loading, setLoading] = useState<boolean>(true);

    const selectedKeys = useSelector(selectSelectedKeys);

    const treeData = useSelector(selectTreeData);

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const { active } = props;
    const { t } = useTranslation();
    const history = useNavigate();
    const IconsStyle = { fontSize: '18px' };

    // Recursive function to get all keys of a node and its children
    const getAllNodeKeys = (node: TreeDataNode): string[] => {
        const childKeys =
            node.children && node.children.length
                ? node.children.flatMap((child) => getAllNodeKeys(child))
                : [];

        return [node.key, ...childKeys];
    };

    function collectSensorNodes(
        node: TreeDataNode,
        sensorNodes: TreeDataNode[],
    ) {
        if (node?.type === 'sensor') {
            sensorNodes.push(node);
        } else {
            node?.children?.forEach((child) => {
                collectSensorNodes(child, sensorNodes);
            });
        }
    }

    function findParentNode(
        data: TreeDataNode[],
        targetNode: TreeDataNode,
    ): TreeDataNode | null {
        const findParent = (
            node: TreeDataNode,
            targetNode: TreeDataNode,
            parent: TreeDataNode | null,
        ): TreeDataNode | null => {
            if (node.key === targetNode.key) {
                return parent;
            }
            if (node && node.children && node.children.length) {
                for (const childNode of node.children) {
                    const result = findParent(childNode, targetNode, node);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };

        // Start traversal from the root node
        for (const node of data) {
            const parent = findParent(node, targetNode, null);
            if (parent !== null) {
                return parent;
            }
        }

        return null;
    }

    const addSensors = async (
        node: TreeDataNode,
        filteredKeys: string[],
        nodeTitle: string,
        selected: boolean,
        sensorNodes: TreeDataNode[],
    ) => {
        // deslect floor or block keys, and all its sensor keys
        if (!selected && node) {
            if (filteredKeys && filteredKeys.length) {
                dispatch(excludeExistingKeys(filteredKeys));
            }

            dispatch(removeExistingFloorSensor(node?.id));
        } else {
            const sensorIds =
                sensorNodes?.map((node: TreeDataNode) => node.id) || [];
            const selectedFloorsOrBlocks = {
                identifier: nodeTitle,
                id: node.id,
                selectedFloors: sensorIds,
            };

            // if already selected node is floor or block,
            // add to the existing keys

            dispatch(addToExistingKeys(filteredKeys));
            dispatch(addFloorOrBlockSensor(selectedFloorsOrBlocks));
        }
    };

    const onSelect: TreeProps['onSelect'] = async (selectedKeys, info) => {
        const node: any = info.node;
        const selected = info.selected;

        if (
            node &&
            node.type !== 'object' &&
            node.type !== 'floor' &&
            node.type !== 'block'
        ) {
            const isExpanded = expandedKeys.includes(node.key);

            if (isExpanded) {
                setExpandedKeys(expandedKeys.filter((key) => key !== node.key));
            } else {
                setExpandedKeys([...expandedKeys, node.key]);
            }
        }

        if (node?.type === 'block' || node?.type === 'floor') {
            const isExpanded = expandedKeys.includes(node.key);

            if (!isExpanded) {
                setExpandedKeys([...expandedKeys, node.key]);
            }
        } else if (node?.type === 'sensor') {
            const nodes: any[] = info.selectedNodes;

            const selectedSensors = nodes.filter(
                (node) => node.type === 'sensor',
            );

            const stringKeys = nodes
                .filter(
                    (node) =>
                        node.type === 'sensor' ||
                        node.type === 'floor' ||
                        node.type === 'block',
                )
                .map((node) => String(node.key));

            dispatch(setSelectedKeys(stringKeys));

            const sensorSegments = node.key.split('-');
            const objId = sensorSegments.pop();

            if (selectedSensors && selectedSensors.length) {
                if (sensorSegments.length > 1) {
                    dispatch(
                        setSelectedSensors({
                            objectId: objId + '',
                            sensorIds: [
                                ...selectedSensors.map((sensor) => sensor.id),
                            ],
                        }),
                    );

                    // have to remove later on
                    dispatch(
                        setSelectedRealtimeSensors([
                            ...selectedSensors.map((sensor) => sensor.id),
                        ]),
                    );
                }
            } else {
                dispatch(
                    setSelectedSensors({
                        objectId: objId + '',
                        sensorIds: [],
                    }),
                );

                // have to remove later on
                dispatch(setSelectedRealtimeSensors([]));
            }
        } else if (node?.type === 'corp') {
            history(
                Nav.build({
                    path: '/corporations/:corporationId',
                    queryParams: {},
                    routeParams: { corporationId: node.id },
                }),
            );
        } else if (node?.type === 'site') {
            history(
                Nav.build({
                    path: '/sites/objects/:siteId',
                    queryParams: {},
                    routeParams: { siteId: node.id },
                }),
            );
        } else if (node?.type === 'object') {
            if (node) {
                //const objectPagePath = `/analytics/${node.id}`;
                //const isObjectPageOpen = pathname === objectPagePath;

                const isExpanded = expandedKeys.includes(node.key);

                const allNodeKeys = getAllNodeKeys(node);

                await dispatch(clearSelectedKeys());
                await dispatch(
                    setSelectedSensors({
                        objectId: node.id,
                        sensorIds: [],
                    }),
                );

                // have to remove later on
                await dispatch(setSelectedRealtimeSensors([]));

                dispatch(setRefreshFlag(true));

                history(
                    Nav.build({
                        path: '/analytics/:objectId',
                        queryParams: {
                            index: String(params?.index) || '0',
                        },
                        routeParams: { objectId: node.id },
                    }),
                    { replace: true },
                );

                if (!isExpanded) {
                    setExpandedKeys([...expandedKeys, ...allNodeKeys]);
                }
            }
        }

        if (
            node?.type !== 'sensor' &&
            node?.type !== 'block' &&
            node?.type !== 'floor'
        ) {
            const nodes: any[] = info.selectedNodes;

            const filteredKeys = nodes
                .filter((node) => node.type !== 'sensor')
                .map((node) => node.key);
            dispatch(setSelectedKeys(filteredKeys));
        }

        if (node?.type === 'floor') {
            const sensorNodes = node?.children.map(
                (node: TreeDataNode) => node,
            );

            let filteredKeys: string[] = [node?.key];

            let nodeTitle = '';

            const blockNode = findParentNode(treeData, node);

            if (blockNode) {
                const objectNode = findParentNode(treeData, blockNode);
                nodeTitle += `${objectNode?.title}-`;
                nodeTitle += `${blockNode?.title}-`;
            }

            nodeTitle += `${node.title}-${t('Average')}`;

            addSensors(node, filteredKeys, nodeTitle, selected, sensorNodes);
        }

        if (node?.type === 'block') {
            const sensorNodes: TreeDataNode[] = [];

            collectSensorNodes(node, sensorNodes);

            if (sensorNodes && sensorNodes.length) {
                let filteredKeys: string[] = [node?.key];

                let nodeTitle = '';

                const objectNode = findParentNode(treeData, node);

                if (objectNode) {
                    nodeTitle += `${objectNode?.title}-`;
                }

                nodeTitle += `${node?.title}-${t('Average')}`;

                addSensors(
                    node,
                    filteredKeys,
                    nodeTitle,
                    selected,
                    sensorNodes,
                );
            }
        }
        dispatch(setNodeType(node));
    };

    const shouldDisableNode = (node: TreeDataNode) =>
        params?.index !== 0 && node.type === 'sensor';

    const isNodeTypeFloor = (node: TreeDataNode) => node.type === 'floor';

    const disableNodesByType = (data: TreeDataNode[]): TreeDataNode[] => {
        return data.map((node) => {
            const disabled = shouldDisableNode(node);
            const title = isNodeTypeFloor(node)
                ? `${t('Floor')} ${Number(node.title) - 10}`
                : node.title;
            return {
                ...node,
                disabled,
                title,
                children: node.children && disableNodesByType(node.children),
            };
        });
    };

    useEffect(() => {
        if (myData && myData.length) {
            const newData = [];

            for (let i = 0; i < myData.length; i++) {
                newData.push(JSON.parse(myData[i]));
            }

            if (newData && newData.length) {
                dispatch(setTreeData(newData));
                setLoading(false);
            }
        }
    }, [myData, dispatch]);

    const onExpand: TreeProps['onExpand'] = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys);
    };

    return (
        <>
            <div id={style['sidebar-overlay']} />
            <div id={style['sidebar-footer']} />
            <div
                className="h-full border-r p-3 w-52"
                style={{
                    width: '22rem',
                    overflow: 'auto',
                    paddingBottom: '4rem',
                }}
            >
                <Row className="mt-10" align="middle" gutter={[60, 20]}>
                    <Col span={6}>
                        <div className="flex ">
                            <div className="w-12 h-12 flex-none m-auto ">
                                {me && (
                                    <CorporationLogo
                                        corporation={me.corporations[0]}
                                    />
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col span={18}>
                        <Typography.Title
                            style={{
                                marginBottom: '0',
                                whiteSpace: 'normal',
                            }}
                            level={4}
                            ellipsis={{ expandable: false }}
                            className="line-clamp-2"
                        >
                            {me?.corporations[0].name}
                        </Typography.Title>
                        <Typography.Paragraph
                            ellipsis={{ rows: 1, suffix: ' ' }}
                            className="line-clamp-2 overflow-x-hidden "
                        >
                            {me?.user.name}
                        </Typography.Paragraph>
                    </Col>
                </Row>
                <Divider />
                <SideTab
                    name={t('Home')}
                    active={active === 'home'}
                    onClick={() => history(Nav.build({ path: '/home' }))}
                    icon={<HomeOutlined style={IconsStyle} />}
                />
                <SideTab
                    name={t('Sites')}
                    active={active === 'sites'}
                    onClick={() => history(Nav.build({ path: '/sites' }))}
                    icon={<ControlOutlined style={IconsStyle} />}
                />
                {loading ? (
                    // Display a loader while the data is being loaded
                    <Skeleton active />
                ) : (
                    // Display the tree once the data is loaded
                    !!treeData.length && (
                        <Tree
                            showLine
                            switcherIcon={<CaretDownFilled />}
                            defaultExpandedKeys={[]}
                            onSelect={onSelect}
                            treeData={disableNodesByType(treeData)}
                            style={{ fontSize: '16px' }}
                            selectedKeys={selectedKeys}
                            multiple={true}
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                        />
                    )
                )}
            </div>
        </>
    );
}

export default Sidebar;
